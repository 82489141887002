.home {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.home-wrapper {
    max-width: 1100px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    color: black;
    overflow: hidden;
    z-index: 2;

}

.level {
    position: absolute;
    top: 5.04%;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 100;

}

.level>h1 {
    font-weight: 200;
    font-size: 30px;
    line-height: 1.5;
    z-index: 2;
}

.level>h6 {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 50;
    font-size: 15px;
    letter-spacing: 3px;
    z-index: 2;
}

.logo {
    width: 200px;
    height: 200px;
    vertical-align: middle;
}

.logo>img {
    width: 100%;
    height: 100%;
    z-index: 2;

}

.home-wrapper>h1 {
    font-size: 40px;
    letter-spacing: 16px;
    margin: 0 auto 21px;
    line-height: 1.5;
    font-weight: normal;
    text-transform: uppercase;
    transition: linear;
    z-index: 2;

}

.home-wrapper>p {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    margin: 0 0 30px 0;
    line-height: 1.88;
    letter-spacing: .5px;
    max-width: 30rem;
    z-index: 2;

}


.home-wrapper>button,
.home-wrapper>a>button {
    background-color: transparent;
    text-transform: uppercase;
    font-size: 13px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: rgba(0, 0, 0, 0.5);
    padding: 10px 30px;
    letter-spacing: 2px;
    font-weight: 500;
    position: relative;
    z-index: 2;
    transition: 0.5s ease;
}

.home-wrapper>button::before,
.home-wrapper>a>button::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: black;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transition: 0.5s ease;
    transform-origin: left;
}

.home-wrapper>button::after,
.home-wrapper>a>button::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    top: 0;
    right: 0;
    background-color: black;
    transition: 0.5s ease;
    transform: scaleY(0);
    transform-origin: bottom;
    transition-delay: 0.4s;

}

.home-wrapper>button:hover ,
.home-wrapper>a>button:hover {
    color: #000;
    border: 1px solid #000;
}

.home-wrapper>button:hover::before,
.home-wrapper>a>button:hover::before {
    transform: scaleX(1);
}

.home-wrapper>button:hover::after ,
.home-wrapper>a>button:hover::after{
    transform: scaleY(1);
}

.animateBox {
    width: 100px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.4);
    position: absolute;
    animation: animatebox infinite linear;
    animation-duration: 12s;
    z-index: 1;
}

.dark>p {
    color: rgba(255, 255, 255, 0.7);

}

.dark>a>button {
    color: rgba(255, 255, 255, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.5);

}

.dark>button:hover ,
.dark>a>button:hover {
    color: #fff;
    border: 1px solid #fff;
}

.dark>a>button::before {
    background-color: white;
}

.dark>a>button::after {
    background-color: white;
}

@keyframes animatebox {
    0% {
        clip-path: polygon(0 25%, 100% 0%, 100% 69%, 12% 75%);
    }

    25% {
        clip-path: polygon(0 44%, 48% 0, 100% 59%, 22% 86%);
    }

    50% {
        clip-path: polygon(0 44%, 79% 0, 100% 35%, 44% 76%);
    }

    75% {
        clip-path: polygon(12% 36%, 72% 4%, 82% 77%, 22% 82%);
    }

    100% {
        clip-path: polygon(0 25%, 100% 0%, 100% 69%, 12% 75%);

    }
}

@media screen and (max-width:1100px) {

    .home-wrapper {
        padding: 50px 0px 60px 0px;
    }

    .logo {
        width: 160px;
        height: 160px;
    }
}

@media screen and (max-width:650px) {
    .home-wrapper>h1 {
        font-size: 30px;
        letter-spacing: 12px;
        padding: 0px 5px;
    }

    .footerRes>h1 {
        font-size: 14px;
        letter-spacing: 2px;
    }

}

@media screen and (max-width:500px) {
    .home-wrapper>h1 {
        line-height: 1.2;
    }

    .level {
        top: 3%;
    }

    .home-wrapper>p {
        line-height: 1.5;
        max-width: 20rem;
        font-size: 15px;
    }
}

@media screen and (max-width:350px) {
    .logo {
        width: 140px;
        height: 140px;
    }

    .home-wrapper>h1 {
        font-size: 25px;
    }

    .home-wrapper>p {
        line-height: 1.3;
        max-width: 16rem;
        font-size: 13px;
    }
}

@media screen and (max-height:650px) {

    .home-wrapper {
        margin-top: 10;
    }

    .home {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
    .logo {
        margin-top: -10px;
        width: 170px;
        height: 170px;
    }
}

@media screen and (max-height:610px) {
   

    .home-wrapper {
        margin-top: -10;
    }

    .level {
        top: 1.5%;
    }

    .logo {
        margin-top: -10px;
        width: 130px;
        height: 130px;
    }

    .home-wrapper>h1 {
        font-size: 25px;
    }

    .home-wrapper>p {
        line-height: 1.2;
        max-width: 16rem;
        font-size: 13px;
        margin-bottom: 12px;
    }

    .home-wrapper>button {
        padding: 7px 15px;
    }

}

@media screen and (max-height:600px) {

    .level {
        top: 2%;
    }

    .level>h1 {
        font-size: 25px;
    }

    .level>h6 {
        font-size: 12px;
    }

    .logo {
        margin-top: -20px;
        width: 120px;
        height: 120px;
    }

    .home-wrapper>h1 {
        font-size: 21px;
    }

    .home-wrapper>p {
        line-height: 1.2;
        max-width: 16rem;
        font-size: 13px;
        margin-bottom: 12px;
        margin-top: -10xpx;
    }

    .home-wrapper>button {
        padding: 7px 15px;
    }

    .animateBox {
        width: 80px;
        height: 70px;
    }
}

@media screen and (max-height:550px) {
    .level {
        top: 2%;
    }

    .level>h1 {
        font-size: 23px;
    }

    .level>h6 {
        font-size: 12px;
    }

    .logo {
        margin-top: -40px;
        width: 110px;
        height: 110px;
    }

    .home-wrapper>h1 {
        font-size: 20px;
    }

    .home-wrapper>p {
        line-height: 1.2;
        max-width: 16rem;
        font-size: 12px;
        margin-bottom: 12px;
        margin-top: -10xpx;
    }

    .home-wrapper>button {
        padding: 6px 12px;
        font-size: 12px;

    }

}

@media screen and (max-height:500px) {
    .level {
        top: 2%;
    }

    .level>h1 {
        font-size: 21px;
    }

    .level>h6 {
        font-size: 10px;
    }

    .logo {
        margin-top: -40px;
        width: 100px;
        height: 100px;
    }

    .home-wrapper>h1 {
        font-size: 18px;
    }

    .home-wrapper>p {
        line-height: 1.2;
        max-width: 16rem;
        font-size: 11px;
        margin-bottom: 12px;
        margin-top: -10xpx;
    }

    .home-wrapper>button {
        padding: 5px 10px;
        font-size: 12px;
    }

}

@media screen and (max-height:425px) {

    .home-wrapper>button {
        visibility: hidden;
    }

}