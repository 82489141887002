.team-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.team {
    max-width: 1400px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 0px 50px 0px;
    color: black;


}

.team>h1 {
    margin: 0 auto 30px;
    letter-spacing: 8px;
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    text-transform: uppercase;
    position: relative;
}

.team>h1::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: black;
    bottom: 0;
    left: 0;
    animation: heading 3s infinite linear;
}

.teamboxs {
    width: 100%;
    transition: 1s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.teambox {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: 1s linear;
    transform: scale(1);
    z-index: 2;

}

.teambox>div:nth-child(1) {
    width: 80%;
    height: 200px;
    border-radius: 5px;
    margin-right: 30px;
    transition: 0.5s ease;
    margin-bottom: 10px;

}

.teamboxImg {
    background: url(../../Assets/Img/hasnain.jpg);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    z-index: 2;
}

.teamboxImg1 {
    background: url(../../Assets/Img/sohaib.jpg);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
}


.teambox:hover .teamboxImg,
.teambox:hover .teamboxImg1,
.teambox:hover .teamboxImg2 {
    opacity: 1;
    transform: scale(1.1);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.teamText {
    overflow: hidden;
    margin: 30px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: black;

}

.teamText>p {
    color: rgba(0, 0, 0, 0.7);
    letter-spacing: .9px;
    font-size: 18px;
    font-weight: lighter;
    margin: 0 0 20px 0;
    line-height: 1.88;

}

.teamText>h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 1.4;
    font-weight: normal;
    text-transform: uppercase;
    width: 80%;
}

.teamText>h1 {
    transition: 0.5s ease;
    transform: translateX(-300px);
}

.teamText>p {
    transition: 0.5s ease;
    transform: translateX(300px);
}

.teambox:hover .teamText>h1 {
    transform: translateX(0px);
}

.teambox:hover .teamText>p {
    transform: translateX(0px);
}

.team-Menu {
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    transform: scale(0);
    display: none;
}

.apple {
    transition: 0.5s ease;
    background: linear-gradient(rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(../../Assets/Img/hasnain.jpg);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    transform: scale(0);
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1100;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.apple1 {
    background: linear-gradient(rgba(225, 225, 225, 0.7), rgba(225, 225, 225, 0.7)), url(../../Assets/Img/sohaib.jpg);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    transform: scale(0);

}

.TeamBIgOpen {
    transform: scale(1);
    transition: 0.5s ease;
}

.TeamBIgClose {
    margin-left: -1000px;
    top: -100px;
    transform: scale(0);
    opacity: 0;
    transition: 0.5s linear;
}



.TeamBIgClose1 {
    margin-left: 1000px;
    margin-top: -100px;
    transform: scale(0);
    opacity: 0;
    transition: 0.5s linear;

}

.apple-title {
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
    color: white;
    padding: 50px;
    position: fixed;
    bottom: 10%;
    clip-path: polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%);
}

.apple-title>h2 {
    font-family: "Abel", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    color: white;
    font-size: 40px;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 8px;
    font-size: 30px;
    letter-spacing: 5px;
    font-weight: 500;
    margin: 0px 0px 20px 0px;
}

.apple-title>h1 {
    font-size: 30px;
    letter-spacing: 6px;
    line-height: 1.4;
    color: white;
    font-weight: normal;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.apple-title>p {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.897);
    font-weight: 200;
    font-family: 'Abel', sans-serif;
}

.appleClose {
    background-color: white;
    width: 100%;
    position: absolute;
    bottom: 0%;
    padding: 20px;
    display: flex;
    justify-content: right;
    align-items: center;
    text-align: center;
    z-index: 1100;
}

.X {
    width: 30px;
    height: 30px;
    position: relative;
    transition: 0.5s linear;
    cursor: pointer;
}

.X::before {
    content: '';
    position: absolute;
    top: 0;
    background-color: black;
    width: 3px;
    height: 100%;
    left: 0;
    transform: rotate(42deg) translate(10px, -9px);
    transition: 0.5s linear;

}

.X::after {
    content: '';
    position: absolute;
    top: 0;
    background-color: black;
    width: 3px;
    height: 100%;
    right: 0;
    transform: rotate(-42deg) translate(-9px, -9px);
    transition: 0.5s linear;

}

.appleClose>h1 {
    margin-right: 30px;
    font-size: 40px;
}

/* Dark Mode */


.darkteam>h1 {
    color: white;
    text-shadow: 0px 0px 10px black, 0px 0px 10px black;
}

.darkteam>h1::before {
    background-color: white;
}

.darkteam .teamboxs .apple {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../Assets/Img/hasnain.jpg);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}

.darkteam .teamboxs .apple1 {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../Assets/Img/sohaib.jpg);
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
}



.darkteam .teamboxs .apple .apple-title {
    background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6));
}

.darkteam .teamboxs .apple .apple-title>h1 {
    color: black;
}

.darkteam .teamboxs .apple .apple-title>h2 {
    color: black;
}

.darkteam .teamboxs .apple .apple-title>p {
    color: rgba(0, 0, 0, 0.897);
}

.darkteam .teamboxs .apple .appleClose {
    background-color: black;
}

.darkteam .teamboxs .apple .appleClose .X::before,
.darkteam .teamboxs .apple .appleClose .X::after {
    background-color: white;
}

.darkteam .teamboxs .teambox .teamText>p {
    color: #eee;
}

.darkteam .teamboxs .teambox .teamText>h1 {
    color: #fff;
}

@media screen and (max-width:800px) {
    .teambox {
        width: 25%;
    }

    .teamText>h1 {
        font-size: 25px;
        letter-spacing: 4px;
        width: 70%;
        line-height: 1.2;
    }

    .teamText>p {
        letter-spacing: .5px;
        font-size: 15px;
    }
}

@media screen and (max-width:550px) {
    .team>h1 {
        letter-spacing: 5px;
        font-size: 35px;
        margin-top: -10px;
        margin-bottom: 50px;
    }

    .teamboxs {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: baseline;
        margin: 0;
        margin-top: -40px;
    }

    .teambox {
        display: flex;
        flex-direction: row;
        justify-content: left;
        margin-left: 30px;
        width: 100%;
        margin-top: 5px;

    }

    .teambox>div:nth-child(1) {
        width: 50%;
        height: 160px;
        border-radius: 5px;
        margin-right: 0px;
    }

    .teamText>h1 {
        font-size: 20px;
        letter-spacing: 3px;
        width: 70%;
        line-height: 1.2;
    }

    .teamText>p {
        letter-spacing: .5px;
        font-size: 12px;
        margin-bottom: 10px;
    }

}

@media screen and (max-width:670px) {

    .apple {
        display: none;
    }
}

@media screen and (max-height:670px) and (max-width:550px) {

    .teambox>div:nth-child(1) {
        height: 140px;
        width: 45%;
    }
}

@media screen and (max-height:600px) and (max-width:550px) {
    .team>h1 {
        letter-spacing: 5px;
        font-size: 30px;
        margin-top: -10px;
        margin-bottom: 50px;
    }

    .teambox>div:nth-child(1) {
        height: 120px;
        width: 40%;
    }
}

@media screen and (max-height:540px) and (max-width:550px) {

    .teambox>div:nth-child(1) {
        height: 100px;
        width: 40%;
    }

    .teambox {
        margin-top: 20px;
    }
}