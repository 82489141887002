.NightMode {
    display: flex;
    justify-content: space-between;
    border: 2px solid rgba(0, 0, 0, 0.7);
    border-radius: 1rem;
    padding: 2px;
    position: relative;
    cursor: pointer;
    transition: 1s linear;
}

.NightMode>* {
    width: 1rem;
    height: 1rem;
    color:rgba(0, 0, 0, 0.8);
}

.N-btn {
    background-color:rgba(0, 0, 0, 0.8);
    border-radius: 100%;
    position: absolute;
    transition: 0.5s ease;
}

