.bubble {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 0;
}

.bubble>img {
    width: 50px;
    animation: bubble 7s linear infinite;
    z-index: 1;
    opacity: 0.4;
    transform: translateY(100px);
}

@keyframes bubble {
    0% {
        transform: translateY(0);
        opacity: 0;
    }
    50% {
        opacity: 0.4;
    }
    70% {
        opacity: 0.4;
    }
    100% {
        transform: translateY(-80vh);
        opacity: 0;
    }

}
.bubble>img:nth-child(1){
    animation-delay: 2s;
    width: 45px;
}
.bubble>img:nth-child(2){
    animation-delay: 1s;    
}
.bubble>img:nth-child(3){
    animation-delay: 3s;
    width: 20px;
}
.bubble>img:nth-child(4){
    animation-delay: 4.5s;
}
.bubble>img:nth-child(5){
    width: 100px;
    animation-delay: 3s;
}
.bubble>img:nth-child(6){
    animation-delay: 6s;
}
.bubble>img:nth-child(7){
    width: 40px;
    animation-delay: 9s;
}
@media screen and (max-width:400px) {
   
    .bubble>img:nth-child(2){
        display: none;
    }

    .bubble>img:nth-child(4){
        display: none;
    }

    .bubble>img:nth-child(6){
        display: none;
    }
    .bubble>img:nth-child(3){
        display: none;
    }
}
@media screen and (max-width:200px) {
   
   .bubble{
    overflow: hidden;
   }

}