.about-wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
}

.about {
    max-width: 1100px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
    z-index: 2;

}

.about>h1 {
    margin: 0 auto 21px;
    letter-spacing: 8px;
    text-align: center;
    font-size: 40px;
    font-weight: normal;
    position: relative;
    z-index: 2;

}

.about>h1::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    background-color: black;
    bottom: 0;
    left: 0;
    animation: heading 3s infinite linear;
}

.about>p:nth-of-type(1) {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    margin: 0 0 30px 0;
    line-height: 1.88;
    letter-spacing: 0.5px;
    padding: 0px 150px;
    z-index: 2;
    max-width: 78%;
}

.about>p:nth-of-type(2) {
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    letter-spacing: 0.74px;
    line-height: 1.88;
    padding: 0px 150px;
    z-index: 2;
    max-width: 78%;
}

.about>h6 {
    font-size: 12px;
    font-family: "Abel", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #000;
    text-transform: uppercase;
    margin: 9px 0 0 0;
    letter-spacing: 2.4px;
    line-height: 1.4;
    z-index: 2;
    font-weight: 500;
}

.animateBorder {
    width: 50px;
    height: 50px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
    animation: animateborder infinite linear;
    z-index: 2;
    animation-duration: 12s;

}

/* Dark Mode */
.darkabout>h1 {
    color: white;
    text-shadow: 0px 0px 10px #000, 0px 0px 10px #000;
}

.darkabout>h1::before {
    background-color: white;
}

.darkabout>p:nth-of-type(1) {
    color: #ddd;
}

.darkabout>p:nth-of-type(2) {
    color: #ddd;
}

.darkabout .animateBorder {
    border: 1px solid rgba(255, 255, 255, 0.7);
}

.darkabout>h6 {
    color: white;
}

@keyframes animateborder {
    0% {
        border-radius: 72% 28% 52% 48% / 58% 78% 22% 42%;
    }

    25% {
        border-radius: 87% 13% 46% 54% / 56% 34% 66% 44%;
    }

    50% {
        border-radius: 15% 85% 6% 94% / 78% 34% 66% 22%;
    }

    75% {
        border-radius: 32% 68% 59% 41% / 66% 34% 66% 34%;
    }

    100% {
        border-radius: 72% 28% 52% 48% / 58% 78% 22% 42%;
    }

}

@media screen and (max-width:900px) {
    .about>p:nth-of-type(1) {
        padding: 0px;
    }

    .about>p:nth-of-type(2) {
        padding: 0px;

    }

}

@media screen and (max-width:500px) {
    .about>h1 {
        font-size: 35px;
        letter-spacing: 5px;
    }

    .about>p:nth-of-type(1) {
        line-height: 1.6;
        padding: 0px;
        font-size: 15px;
    }

    .about>p:nth-of-type(2) {
        font-size: 15px;
        line-height: 1.6;
        padding: 0px;

    }

}

@media screen and (max-width:500px) {
    .about-wrapper {
        margin-top: -30px;
    }

    .about>p:nth-of-type(1) {

        max-width: 90%;
    }

    .about>p:nth-of-type(2) {

        max-width: 90%;
    }

}

@media screen and (max-width:250px) {
    .about-wrapper {
        margin-top: -30px;
    }

    .about>h1 {
        font-size: 30px;
        letter-spacing: 4px;
    }

    .about>p:nth-of-type(1) {
        line-height: 1.5;
        padding: 0px;
        font-size: 13px;
    }

    .about>p:nth-of-type(2) {
        line-height: 1.5;
        padding: 0px;
        font-size: 13px;

    }

}

@media screen and (max-height:630px) {
    .about {
        margin-top: -30px;
        ;
    }

    .about>h1 {
        margin: 0 auto 15px;
    }

    .about>p:nth-of-type(1) {
        margin: 0 0 20px 0;
        line-height: 1.6;
    }

    .animateBorder {
        margin-bottom: 10px;
    }

    .about>h6 {
        margin: 5px 0 0 0;
    }

    .about>p:nth-of-type(2) {
        line-height: 1.6;
    }
}



@media screen and (max-height:560px) {
    .about {
        margin-top: -5px;
    }

    .about>h1 {
        margin: 0 auto 10px;
    }

    .about>p:nth-of-type(1) {
        margin: 0 0 10px 0;
        line-height: 1.5;
    }

    .animateBorder {
        margin-bottom: 6px;
    }

    .about>h6 {
        margin: 3px 0 0 0;
    }

    .about>p:nth-of-type(2) {
        line-height: 1.5;
    }
}
@media screen and (max-height:530px) {
    .about {
        margin-top: -5px;
    }

    .about>h1 {
        margin: 0 auto 10px;
    }

    .about>p:nth-of-type(1) {
        margin: 0 0 10px 0;
        line-height: 1.5;
        font-size: 13px;

    }

    .animateBorder {
        margin-bottom: 6px;
    }

    .about>h6 {
        margin: 3px 0 0 0;
        
    }

    .about>p:nth-of-type(2) {
        line-height: 1.5;
        font-size: 13px;
    }
}