.testimonial-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}

.testimonial {
  max-width: 1400px;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;

}

.testimonial>h1 {
  margin: 0 auto 30px;
  letter-spacing: 8px;
  text-align: center;
  font-size: 40px;
  font-weight: normal;
  text-transform: uppercase;
  position: relative;
}

.testimonial>h1::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  background-color: black;
  bottom: 0;
  left: 0;
  animation: heading 3s infinite linear;
}

@keyframes heading {
  0% {
    transform: scaleX(0);
  }

  50% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.testimonialSwiper .swiper {
  width: 100%;
  padding-bottom: 50px;
  overflow: hidden;
}

.testimonialSwiper .swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: max-content;

}

.testimonialSwiper .swiper-slide img {
  display: block;
  width: 100%;
}

.test {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  flex-direction: column;
  overflow: hidden;

}

/* .test1 {
  background-color: #000;
  color: white;
} */

.test1>.testimg {
  border: 2px solid white;
  background-image: url(../../Assets/Img/test1.avif);
}

/* .test1>.testtitle>h1 {
  color: white;
} */

.testimg {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid black;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.testimg>img {
  width: 100%;
  height: 100%;
}

.testtitle>h1 {
  color: black;
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.2px;
  text-shadow: 2px 2px 10px #ccc;
  margin-bottom: 10px;
}

.testdec>p {
  color: #aaa;
  font-size: 15px;
  font-weight: 100;
  overflow: hidden;
}

/* Dark Mode */
.darktestimonial>h1 {
  color: white;
  text-shadow: 0px 0px 10px #000, 0px 0px 10px #000;

}

.darktestimonial>h1::before {
  background-color: white;
}
.darktestimonial .testimonialSwiper .swiper .swiper-slide .test{
  background: black;
  border-radius: 18px;
}
.darktestimonial .testimonialSwiper .swiper .swiper-slide .test .testtitle>h1{
color: white;
}
@media screen and (max-width:500px) {
  .testimonialSwiper .swiper-slide {
    width: 280px;
  }

  .testimonial>h1 {
    letter-spacing: 5px;
    font-size: 30px;
  }


}

@media screen and (max-height:600px) {
  .testimonial-wrapper {
    display: inherit;
    margin-top: 20px;
    justify-content: inherit;
  }


  .testimonial>h1 {
    letter-spacing: 5px;
    font-size: 30px;
  }

}
@media screen and (max-height:510px) {
  .testimonialSwiper .swiper-horizontal>.swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 50px;
  }
  
}